import React, {Component} from 'react';
import Ajax from "../../common/ajax";
import Config from "../../config";
import Content from "../../common/containers/Content";
import Row from "../../common/containers/Row";
import Col from "../../common/containers/Col";
import PrivatePage from "../../common/containers/PrivatePage";
import Waiting from "../../common/containers/Waiting";

class InstitutionsReportPage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            institutions: null,
            processing: true,
        }
    }

    componentDidMount() {
        Ajax.get(`${Config.djangoHost}r/institutions`).done(institutions => {
            this.setState(state => ({
                ...state,
                institutions,
            }));
        }).always(() => {
            this.setState(state => ({
                ...state,
                processing: false,
            }));
        });
    }

    render() {
        return (
            <PrivatePage title="Institutions Report">
                <Content>
                    <Waiting isProcessing={this.state.processing}>
                        <Row>
                            <Col md={6}>
                                <table className="table">
                                    <tbody>
                                    {this.state.institutions && this.state.institutions.map(i => (
                                        <tr key={i[0]}>
                                            <td>{i[0]}</td>
                                            <td>{i[1]}</td>
                                        </tr>
                                    ))}
                                    </tbody>
                                </table>
                            </Col>
                        </Row>
                    </Waiting>
                </Content>
            </PrivatePage>
        );
    }
}

export default InstitutionsReportPage;